import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import DropdownMenu from "./DropdownMenu";
import {A} from "hookrouter";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        boxShadow: 'none'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbarTitle: {
        flex: 1,
    },
    toolbarSecondary: {
        justifyContent: 'space-between',
        overflowX: 'auto',
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
    },
}));

export default function Header(props) {
    const classes = useStyles();
    const { menu, title } = props;

    return (
        <div className={classes.root}>

            <AppBar position="static" color="transparent" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <IconButton component={A} href={'/'}>
                        <HomeIcon />
                    </IconButton>
                    {/*<Button size="small">Subscribe</Button>*/}
                    <Typography
                        component="h2"
                        variant="h5"
                        color="inherit"
                        align="center"
                        noWrap
                        className={classes.toolbarTitle}
                    >
                        {title}
                    </Typography>
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                    {/*<Button variant="outlined" size="small">*/}
                    {/*    Sign up*/}
                    {/*</Button>*/}
                </Toolbar>
                <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
                    {menu.map((x) => (
                        <DropdownMenu menu={x} />
                    ))}
                </Toolbar>
            </AppBar>
        </div>
    );
}
