import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {A} from "hookrouter";

export default function DropdownMenu(props) {
    const { menu } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {menu.menuTitle || menu.postTitle}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                { menu.children && menu.children.map(x => (
                    <MenuItem component={A} onClick={handleClose} href={'/news/' + x.postId + '/' + (x.menuName || x.postName)}>{x.menuTitle || x.postTitle}</MenuItem>
                )) }
            </Menu>
        </>
    );
}
