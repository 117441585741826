import React from 'react';
import {useQuery} from 'graphql-hooks'
import {A} from 'hookrouter';
import CircularProgress from '@material-ui/core/CircularProgress';
import useLazyLoadImage from 'react-use-lazy-load-image';
import Grid from '@material-ui/core/Grid';
import FeaturedPost from './FeaturedPost';
import Button from '@material-ui/core/Button';

const NEWS_LIST = `
    query GetPosts($query: GetPostsQueryInput!) {
        posts(query: $query) {
            id
            name
            title
            content
            date
            author {
                id
                name
            }
            images {
                url
            }
        }
    }
`;

const imgStyle = {border: '1px solid #dedbd1', padding: '3px', width: '100%', height: '150px', minHeight: '150px', minWidth: '100%'};

export function NewsImage(props) {
    useLazyLoadImage();

    const id = props.id;
    const url = props.url;

    return (
        <div key="image" className="col" style={{flex: '0 0 230px'}}>
            <img style={imgStyle} data-img-src={'https://wp.realmadrid.si/crop.php?width=200&height=150&id=' + id + '&image=' + url} url={url} src="https://via.placeholder.com/200x150" alt="" />
        </div>
    )
}

export function NewsList(props) {
    const page = (props.page && parseInt(props.page, 10)) || 1;
    const perPage = 20;
    const { loading, error, data } = useQuery(NEWS_LIST, {
        variables: {
            query: {
                skip: (page - 1) * perPage,
                take: perPage
            }
        }
    });

    if (loading) {
        return (<CircularProgress />);
    }

    if (error) {
        return <p>Napaka :(</p>;
    }

    const { posts } = data;

    return (
        <>
            <Grid container spacing={4}>
                {posts.map((post) => (
                    <FeaturedPost key={post.title} post={post} />
                ))}

                <Grid container mt={2} justify="space-between">
                    <Grid item>
                        {page === 2 && <Button variant="outlined" component={A} href={'/'} className="float-left">&lt; Naprej</Button>}
                        {page > 2 && <Button variant="outlined" component={A} href={'/news/page/' + (page - 1)} className="float-left">&lt; Naprej</Button>}
                    </Grid>
                    <Grid item>
                        {posts.length >= perPage && <Button variant="outlined" component={A} href={'/news/page/' + (page + 1)} className="float-right">Nazaj &gt;</Button >}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
