import React from 'react';
import {useQuery} from 'graphql-hooks'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const FORUM_TOPICS = `
    query GetForumTopics($query: GetForumTopicsQueryInput!) {
        forumTopics(query: $query) {
            id
            forumId
            lastPostId
            lastPostDate
            title
        }
    }
`;

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export function Forum() {
    const { loading, error, data } = useQuery(FORUM_TOPICS, {
        variables: {
            query: {
                take: 5
            }
        }
    });

    if (loading) {
        return (<CircularProgress />);
    }

    if (error) {
        return <p>Napaka :(</p>;
    }

    const {forumTopics} = data;

    return (
        <Grid item>
            <Box mt={2}>
                <Typography variant="h6" gutterBottom>Forum</Typography>
            </Box>

            <Paper>
                <List component="nav">
                    {forumTopics.map(topic => (
                        <ListItemLink button key={topic.id} href={'https://forum.realmadrid.si/viewtopic.php?f=' + topic.forumId + '&p=' + topic.lastPostId + '#p' + topic.lastPostId} title={topic.title} target="_blank" rel="noopener noreferrer">
                            {topic.title}
                        </ListItemLink >))
                    }
                </List >
            </Paper>
        </Grid>
    );
}
