import React from 'react';
import {useQuery} from 'graphql-hooks'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const QUERY = `
    query GetLaLiga {
        matches {
            id
            date
            homeTeam
            homeTeamImageUrl
            awayTeam
            awayTeamImageUrl
            competition
        }
    }
`;

export function Matches() {
    const { loading, error, data } = useQuery(QUERY);

    if (loading) {
        return (<CircularProgress />);
    }

    if (error) {
        return <p>Napaka :(</p>;
    }

    const {matches} = data;

    if (!matches || matches.length === 0) {
        // matches.push({
        //     date: new Date(),
        //     competition: 'Champions League',
        //     homeTeam: 'Real Madrid',
        //     homeTeamImageUrl: 'https://as01.epimg.net/img/comunes/fotos/fichas/equipos/small/2x/1.png',
        //     awayTeam: 'Man City',
        //     awayTeamImageUrl: 'https://as01.epimg.net/img/comunes/fotos/fichas/equipos/small/2x/66.png'
        // });

        return (
            <>
            </>
        )
    }

    return (
        <Grid item>
            <Box mt={2}>
                <Typography variant="h6" gutterBottom>Prihajajoče tekme</Typography>
            </Box>

            <Grid container>
                {matches.map((match) => (
                    <Grid item xs key={match.id}>
                        <Paper>
                            <Box p={2}>
                                <Typography variant={"subtitle2"} style={{marginBottom: '1rem'}}>
                                    {(new Date(match.date).toLocaleDateString())} - {match.competition}
                                </Typography>

                                <Grid container direction="row"
                                      justify="space-between"
                                      alignItems="center">
                                    <Grid item xs={"auto"}>
                                        <img src={match.homeTeamImageUrl} alt={match.homeTeam} style={{ height: 52 }} />
                                        <br/>
                                        {match.homeTeam}
                                    </Grid>
                                    <Grid item xs={"auto"}>vs</Grid>
                                    <Grid item xs={"auto"} justify={"flex-end"} alignContent={"flex-end"}>
                                        <img src={match.awayTeamImageUrl} alt={match.awayTeam} style={{ height: 52 }} />
                                        <br/>
                                        {match.awayTeam}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            {/*<ListGroup className="list-group-flush">*/}
            {/*    {*/}
            {/*        matches.map(match => (*/}
            {/*            <ListGroupItem key={match.id}>*/}
            {/*                <span className="text-muted small">{(new Date(match.date).toLocaleDateString())} - {match.competition}</span>*/}
            {/*                <br/>*/}
            {/*                <div className="d-flex align-items-center text-align-center mt-2">*/}
            {/*                    <div style={{padding: 4, flexBasis: '50%'}}>*/}
            {/*                        <img src={match.homeTeamImageUrl} alt={match.homeTeam} style={{ height: 52 }} /><br/>*/}
            {/*                        {match.homeTeam}*/}
            {/*                    </div>*/}
            {/*                    <div style={{padding: 4, flexBasis: 20}}> vs </div>*/}
            {/*                    <div style={{padding: 4, flexBasis: '50%'}}>*/}
            {/*                        <img src={match.awayTeamImageUrl} alt={match.awayTeam} style={{ height: 52 }} /><br/>*/}
            {/*                        {match.awayTeam}*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </ListGroupItem>*/}
            {/*        ))*/}
            {/*    }*/}
            {/*</ListGroup>*/}
        </Grid>
    );
}
