import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import useLazyLoadImage from 'react-use-lazy-load-image';
import {A} from 'hookrouter';

const useStyles = makeStyles({
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
        width: 160,
    },
    cardTitle: {
        maxHeight: 'calc(3rem * 1.334)',
        overflow: 'hidden',
        display: 'inline-block',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        textOverflow: 'ellipsis',
        whiteSpace: 'no-wrap'
    }
});

export default function FeaturedPost(props) {
    const classes = useStyles();
    useLazyLoadImage();
    const { post } = props;
    const url = post.images[0].url;

    return (
        <Grid item xs={12} md={6} title={post.title}>
            <CardActionArea component={A} href={'/news/' + post.id + '/' + post.name}>
                <Card className={classes.card}>
                    <div className={classes.cardDetails}>
                        <CardContent>
                            <Typography component="h3" variant="h6" className={classes.cardTitle}>
                                {post.title}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {new Date(post.date).toLocaleDateString()}
                            </Typography>
                            <Typography variant="subtitle1" paragraph>
                                {post.description}
                            </Typography>
                            <Typography variant="subtitle1" color="primary">
                                Preberite več...
                            </Typography>
                        </CardContent>
                    </div>
                    <Hidden xsDown>
                        <CardMedia className={classes.cardMedia} image={url} title={post.imageTitle}>
                            {/*<img data-img-src={'https://wp.realmadrid.si/crop.php?width=200&height=150&id=' + post.id + '&image=' + url} url={url} src="https://via.placeholder.com/200x160" alt="" />*/}
                        </CardMedia>
                    </Hidden>
                </Card>
            </CardActionArea>
        </Grid>
    );
}
