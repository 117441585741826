import React, {useEffect} from 'react';
import {useRoutes} from 'hookrouter';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {ThemeProvider} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import CircularProgress from '@material-ui/core/CircularProgress';

import {NewsList} from './components/NewsList';
import {NewsView} from './components/NewsView';
import {NotFound} from './components/NotFound';
import {HomePage} from './components/Home';
import {useQuery} from 'graphql-hooks'

import Footer from './components/Footer';
import Header from './components/Header';
import useWindowDimensions from "./hooks/useWindowDimensions";

const routes = {
    '/': () => <HomePage/>,
    '/news': () => <NewsList/>,
    '/news/page/:page': ({page}) => <NewsList page={page}/>,
    '/news/:id/:name': ({id, name}) => <NewsView id={id} name={name}/>
};

const MENU_QUERY = `
    fragment MenuFragment on MenuItem {
        id
        postId
        postName
        postTitle
        menuName
        menuTitle
        menuOrder
        parent {
            id
        }
    }
    
    query GetMenu {
        menu {
            ...MenuFragment,
            children {
                ...MenuFragment
                children {
                    ...MenuFragment
                }
            }
        }
    }
`;

const sortMenuFn = (x, y) => x.menuOrder > y.menuOrder ? 1 : (y.menuOrder > x.menuOrder ? -1 : 0);

function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    //const prefersDarkMode = false;
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    primary: blue,
                    background: {
                        default: prefersDarkMode ? '#121212' : '#f4f4f4'
                    }
                },
            }),
        [prefersDarkMode],
    );


    const routeResult = useRoutes(routes);
    useEffect(() => window.scrollTo(0, 0), [routeResult]);
    const { height } = useWindowDimensions();
    const { loading, error, data } = useQuery(MENU_QUERY);

    if (loading) {
        return (<CircularProgress />);
    }

    if (error) {
        return <p>Napaka :( {error}</p>;
    }

    const menu = data.menu.filter(x => x.postTitle[0] !== '<' && x.postTitle !== 'Domov' && x.postTitle !== 'Forum');
    menu.sort(sortMenuFn);
    menu.filter(x => x.children).forEach(x => {
        x.children.sort(sortMenuFn);
    })

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>

            <Container maxWidth="lg" style={{marginBottom: '3rem'}}>
                <Header title="Real Madrid Slovenija" menu={menu} />

                <main style={{marginTop: '1rem', minHeight: height}}>
                    { routeResult || <NotFound/> }
                </main>
            </Container>

            <Footer title="Footer" description="Something here to give the footer a purpose!" />
        </ThemeProvider>
    );
}

export default App;
