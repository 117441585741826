import React from 'react';

import Grid from '@material-ui/core/Grid';

export function FanClub() {
    return (
        <Grid item>
            <a href="https://wp.realmadrid.si/klub-navijacev/">
                <img className="w-100" src="https://wp.realmadrid.si/knrms.png" alt="Klub navijačev Real Madrid Slovenija" title="Klub navijačev Real Madrid Slovenija" />
            </a>
        </Grid>
    );
}
