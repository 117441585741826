import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {GraphQLClient, ClientContext} from 'graphql-hooks'

const client2 = new GraphQLClient({
    // eslint-disable-next-line no-restricted-globals
    url: location.hostname === 'localhost' ? 'http://localhost:5000/graphql' : 'https://api.realmadrid.si/graphql'
});

ReactDOM.render(
     <ClientContext.Provider value={client2}>
         <App/>
     </ClientContext.Provider>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
