import React, {useEffect} from 'react';
import {useQuery} from 'graphql-hooks'
import CircularProgress from '@material-ui/core/CircularProgress';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {FanClub} from './FanClub';
import {LaLiga} from './LaLiga';
import {Forum} from './Forum';
import {Matches} from './Matches';

const NEWS = `
    query GetPost($query: GetPostQueryInput!) {
        post(query: $query) {
            id
            title
            content
            date
            author {
                id
                name
            }
            images {
                url
            }
        }
    }
`;

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export function NewsView(props) {
    const classes = useStyles();
    const { loading, error, data } = useQuery(NEWS, {
        variables: {
            query: {
                id: props.id
            }
        }
    });

    useEffect(() => {
        if (data && data.post && data.post.title) {
            document.title = 'Real Madrid Slovenija - ' + data.post.title;
        }

        return () => {
            document.title = 'Real Madrid Slovenija';
        }
    }, [data])

    if (loading) {
        return (<CircularProgress />);
    }

    if (error) {
        return <p>Napaka :(</p>;
    }

    const { post } = data;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={9}>
                <Card className={classes.root}>
                    <CardHeader title={post.title} subheader={new Date(post.date).toLocaleDateString() + ', ' + post.author.name}></CardHeader>
                    <CardContent className="post" dangerouslySetInnerHTML={{ __html: post.content }}></CardContent>
                </Card>
            </Grid>
            <Grid item xs={0} lg={3}>
                <FanClub />
                <Matches />
                <LaLiga />
                <Forum />
            </Grid>
        </Grid>
    );
}
