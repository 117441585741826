import React from 'react';
import {A} from 'hookrouter';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useQuery} from 'graphql-hooks'
import useLazyLoadImage from 'react-use-lazy-load-image';
import {FanClub} from './FanClub';
import {Matches} from './Matches';
import {LaLiga} from './LaLiga';
import {Forum} from './Forum';
import MainFeaturedPost from './MainFeaturedPost';
import Grid from '@material-ui/core/Grid';
import FeaturedPost from './FeaturedPost';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const NEWS_LIST = `
    query GetPosts($query: GetPostsQueryInput!) {
        posts(query: $query) {
            id
            name
            title
            summary
            date
            author {
                id
                name
            }
            images {
                url
            }
        }
    }
`;

export function HomePage() {
    useLazyLoadImage();

    const page = 1;
    const perPage = 20;
    const { loading, error, data } = useQuery(NEWS_LIST, {
        variables: {
            query: {
                skip: (page - 1) * perPage,
                take: perPage
            }
        }
    });

    if (loading) {
        return (<CircularProgress />);
    }

    if (error) {
        return <p>Napaka :( ${error}</p>;
    }

    const firstNews = data.posts[0];
    const posts = data.posts.filter(x => x !== firstNews);

    return (
        <>
            <MainFeaturedPost post={firstNews} />
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={9}>
                    <Grid container spacing={4}>
                        {posts.map((post) => (
                            <FeaturedPost key={post.title} post={post} />
                        ))}
                    </Grid>

                    <Grid container justify={"flex-end"}>
                        <Grid item>
                            <Box mt={2}>
                                <Button variant="outlined" component={A} className="float-right" href="/news/page/2">Več novic...</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <FanClub />
                    <Matches />
                    <LaLiga />
                    <Forum />
                </Grid>
            </Grid>
        </>
    )
}
