import React from 'react';
import {useQuery} from 'graphql-hooks'
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';

const QUERY = `
    query GetLaLiga {
        laLiga {
            id
            position
            name
            points
        }
    }
`;

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const RealMadridTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#64dd173b',
        },
    },
}))(TableRow);

function RowCells(props) {
    const { row } = props;

    return (
        <>
            <TableCell component="th" scope="row">
                {row.position}
            </TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell align="right">{row.points}</TableCell>
        </>
    )
}

export function LaLiga() {
    const { loading, error, data } = useQuery(QUERY);

    if (loading) {
        return (<CircularProgress />);
    }

    if (error) {
        return <p>Napaka :(</p>;
    }

    const {laLiga} = data;

    return (
        <Grid item>
            <Box mt={2}>
                <Typography variant="h6" gutterBottom>LaLiga Santander</Typography>
            </Box>

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <TableCell width='5%'>#</TableCell>
                            <TableCell width='90%'>Ekipa</TableCell>
                            <TableCell width='5%' align="right">Točke</TableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {laLiga.filter(x => x.position <= 4).map((row) => (
                            ( row.name === 'Real Madrid' ?
                                    <RealMadridTableRow key={row.name} title={row.name}>
                                        <RowCells row={row} />
                                    </RealMadridTableRow> :
                                    <StyledTableRow key={row.name} title={row.name}>
                                        <RowCells row={row} />
                                    </StyledTableRow>
                            )
                        ))}
                        <StyledTableRow key={'...'}>
                            <TableCell component="th" scope="row">...</TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right"></TableCell>
                        </StyledTableRow>
                        {laLiga.filter(x => x.position > 17).map((row) => (
                            <StyledTableRow key={row.name} title={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.position}
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell align="right">{row.points}</TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>


        // <Card>
        //     <Card.Body>
        //         <Card.Title style={{ margin: 0, fontWeight: 'bold' }}>LaLiga Santander</Card.Title>
        //     </Card.Body>
        //
        //
        //     <Table striped hover size="sm" className="mb-0">
        //         <thead>
        //         <tr>
        //             <th></th>
        //             <th>Ekipa</th>
        //             <th>Točke</th>
        //         </tr>
        //         </thead>
        //         <tbody>
        //         {
        //             laLiga.filter(x => x.position <= 4).map(x => (
        //                 <tr key={x.position} className={ 'table-primary ' + (x.name === 'Real Madrid' ? 'table-bold' : '') }>
        //                     <td>{x.position}.</td>
        //                     <td>{x.name}</td>
        //                     <td className="text-center">{x.points}</td>
        //                 </tr>
        //             ))
        //         }
        //         {
        //             laLiga.filter(x => x.position > 4 && x.position < 7).map(x => (
        //                 <tr key={x.position} className={ 'table-warning' + (x.name === 'Real Madrid' ? 'table-bold' : '') }>
        //                     <td>{x.position}.</td>
        //                     <td>{x.name}</td>
        //                     <td className="text-center">{x.points}</td>
        //                 </tr>
        //             ))
        //         }
        //         <tr>
        //             <td className="text-center" colSpan={3}>...</td>
        //         </tr>
        //         {
        //             laLiga.filter(x => x.position > 17).map(x => (
        //                 <tr key={x.position} className={'table-danger' + (x.isRealMadrid ? ' table-bold' : '')}>
        //                     <td>{x.position}.</td>
        //                     <td>{x.name}</td>
        //                     <td className="text-center">{x.points}</td>
        //                 </tr>
        //             ))
        //         }
        //         </tbody>
        //     </Table>
        // </Card>
    );
}
